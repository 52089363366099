.offer_special_container {
	.offer_special {
		header {
			margin-bottom: 32px;
			h2 {
				padding-bottom: 10px;
			}
		}
	}
	.category_teaser {
		padding-top: 10px;
		padding-bottom: 10px;
		h3 {
			min-height: 80px;
		}
	}
}