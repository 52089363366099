.button, .button_offline {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-radius: 2px;
  box-shadow: none;
  color: #05164d;
  font-family: inherit;
  font-weight: 700;
  min-height: 40px;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  margin: 0;
  padding: 0 $col-inner-padding;
  transition: none;
  font-size: 16px;
  outline: none;
  &:hover {
    color: #ffffff;
  }

  .button__text {
    display: inline-block;
    overflow: hidden;
    pointer-events: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      color: #ffffff;
    }
  }

  @media only screen and (min-width:$screen-md) {
    &:hover {
      background: #ebebeb;
    }
  }

  &.text-btn {
    margin:0;
    padding:0;
    height: auto;
    background-color: transparent;
    border: none;
    color: $clink;
    font-size: 16px;
    font-weight: bold;

    >span[class^="mam-"], [class*=" mam-"] {
      font-size: 25px;
    }

    @media only screen and (min-width:$screen-md) {
      &:hover {
        color: $cbrandsecondary;
        span:before {
          color: $cbrandsecondary;
        }
      }
    }
  }

  &.button--large {
    color: #05164d;
    font-weight: 700;
    height: 50px;
    font-size: 18px;
  }

  &.button--success {
    color: #094;
  }

  &.button--warning {
    color: #c02;
  }

  &.button--fullWidth {
    width: 100%;
  }

  &.button--statusFTL {
    color: #6e7882;
  }

  &.button--statusSEN {
    color: #a57314;
  }

  &.button--statusHON {
    color: #191919;
  }

  &.button--filled {
    background: #fff;
    color: #05164d;
  }
  @media only screen and (min-width:$screen-md) {
    &.button--filled:hover {
      background: #ebebeb;
    }
  }

  &.button--filled.button[disabled]:not(.pluginsubmitbutton--submittedAnimated) {
    background: #ebebeb;
    color: #b4b4b4;
  }

  &.button--filled.button--primary {
    background: #05164d;
    color: #fff;
  }

  @media only screen and (min-width:$screen-md) {
    &.button--filled.button--primary:hover {
      background: #041341;
    }
  }

  &.button--filled.button--statusFTL,
  &.button--filled.button--statusHON,
  &.button--filled.button--statusSEN {
    color: #fff;
  }

  &.button--filled.button--statusFTL {
    background: #6e7882;
  }

  @media only screen and (min-width:$screen-md) {
    &.button--filled.button--statusFTL:hover {
      background: #5e666f;
    }
  }

  &.button--filled.button--statusSEN {
    background: #a57314;
  }

  @media only screen and (min-width:$screen-md) {
    &.button--filled.button--statusSEN:hover {
      background: #8c6211;
    }
  }

  &.button--filled.button--statusHON {
    background: #191919;
  }

  &.button--offline {
    border: 2px solid #05164d;
  }

  @media only screen and (min-width:$screen-md) {
    &.button--filled.button--statusHON:hover {
      background: #151515;
    }
  }
}