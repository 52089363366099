.mam_partner_card_group_container {
		.card {
				border-radius: unset;
				border: none;
				margin: 0 5px 5px 5px;
				img {
						border-radius: unset;
				}
				.card-body {
						.left {
								float: left;
								display: inline-block;
								text-align: center;
								img {
									width: 50%;
									height: auto;
								}
								@media only screen and (max-width:$screen-s) {
										text-align: left;
								}
						}
						.right {
								display: inline-block;
								border-left: 2px solid $mam-pale-grey;
								padding: 5px 0 5px 15px;
								.card-text {
										font-weight: initial;
										padding-top: 5px;
										&:first-child {
												padding-top: 0;
										}
								}
								@media only screen and (max-width:$screen-s) {
										border-left: none;
										padding: 0px;
										.card-text:first-child {
												padding-top: 5px;
										}
								}
						}
				}
				&:hover {
            @include box-shadow($box-shadow);
        }
		}
}