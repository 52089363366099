// ColorIds
$mam-lh-blue: #05164D;
$mam-black: #000000;
$mam-dark-grey: #333333;
$mam-dim-grey-darker: #707070;
$mam-dim-grey: #878787;
$mam-medium-grey: #B4B4B4;
$mam-pale-grey: #EBEBEB;
$mam-light-grey: #F5F5F5;
$mam-white: #FFFFFF;
$mam-hon-dark-grey: #282828;
$mam-gold: #8F7231;
$mam-silver: #757575;
$mam-interaction-colour: #1E449C;
$mam-success-green: #009944;
$mam-error-red: #CC0022;
$mam-azur-blue: #7EADF8;
$mam-powder-blue: #B7DDF8;
$mam-accent-blue: #2A5FEA;
$mam-light-highlight-blue: #EAEFFA;
$mam-highlight-blue: #3262CF;
$mam-accordion-text: #505050;

// Theme variables
$clink-accordion: #0C2058;
$chighlight: #3262CF;
$clink: #0c2058;
$cbuttonprimarybg: $mam-lh-blue;
$ccardbgbrand: $mam-lh-blue;
$cbrand: $mam-lh-blue;
$cstatusbase: $mam-lh-blue;
$cpagetitle: $mam-lh-blue;
$cpagetitle-rgba: rgba(12,32,88,0.98);
$cgrey0: $mam-black;
$cbodytext: $mam-dark-grey;
$ccradtext: $mam-dark-grey;
$cgrey1: $mam-dark-grey;
$ctextsecondary: $mam-dim-grey-darker;
$cgrey2: $mam-dim-grey;
$ctextinactive: $mam-medium-grey;
$cstroke: $mam-medium-grey;
$cgrey3: $mam-medium-grey;
$cinactive: $mam-pale-grey;
$cgrey4: $mam-pale-grey;
$clistdivider: $mam-pale-grey;
$clistdivider-rgba: rgba(235,235,235,0.99);
$cbodybg: $mam-light-grey;
$cinputbg: $mam-light-grey;
$cdivider: $mam-light-grey;
$cgrey5: $mam-light-grey;
$ccradbg: $mam-white;
$cbuttondefaultbg: $mam-white;
$clinkinverted: $mam-white;
$cbodytextinverted: $mam-white;
$cbodybg2: $mam-white;
$cstrokeinverted: $mam-white;
$cgrey00: $mam-white;
$cstatushon: $mam-hon-dark-grey;
$cstatussen: $mam-gold;
$cstatusftl: $mam-silver;
$csuccess: $mam-success-green;
$cwarn: $mam-error-red;
$caccent2: $mam-azur-blue;
$caccent3: $mam-powder-blue;
$cbrandsecondary: $mam-accent-blue;
$cnotificationblue: $mam-light-highlight-blue;
$vbuttondefaultbg: #FEFEFE;
$overlay: rgba(0, 0, 0, 0.5);

// Shadows
$enable-shadows: true;
$box-shadow: 0 2px 4px rgba(0, 0, 0, .14), 0 4px 5px rgba(0, 0, 0, .12), 0 1px 10px rgba(0, 0, 0, .2);
$box-shadow-inactive: 0 1px 2px rgba(211, 211, 211, 0.5), 0 2px 3px rgba(211, 211, 211, 0.5), 0 1px 5px rgba(211, 211, 211, 0.5);
$box-shadow-none: unset;

// Sizes
$col-inner-padding: 24px;

.container {
  @media (max-width: $screen-sm-min) {
    padding: 0 16px;
  }
}

// Selectpicker
$color-red-error: rgb(185, 74, 72) !default;
$color-green-success: #28a745;
$color-grey-arrow: rgba(204, 204, 204, 0.2) !default;
$width-default: 220px !default; // 3 960px-grid columns
$zindex-select-dropdown: 1060 !default; // must be higher than a modal background (1050)

// Placeholder text color
$input-color-placeholder: #999 !default;
$input-alt-color-placeholder: rgba(255, 255, 255, 0.5) !default;

$input-padding-y-sm: .25rem !default;
$input-padding-x-sm: .5rem !default;

$input-padding-y-lg: 0.5rem !default;
$input-padding-x-lg: 1rem !default;

$font-path: "../fonts";
$mam-head-font: "LufthansaHeadWeb";
$mam-font: "LufthansaTextWeb";

.mam-head-font-family {
  font-family: "LufthansaHeadWeb", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.mam-font-family {
  font-family: "LufthansaTextWeb", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

h1 {
  @extend .mam-head-font-family;
  font-size: 48px !important;
  line-height: 56px !important;
}

h2 {
  @extend .mam-head-font-family;
  font-size: 36px !important;
  line-height: 42px !important;
}

h3 {
  @extend .mam-head-font-family;
  font-size: 30px !important;
  line-height: 36px !important;
  font-weight: bold;
}

h4 {
  @extend .mam-head-font-family;
  font-size: 24px !important;
  line-height: 30px !important;
}

@media (max-width: $screen-sm-min) {
  h1 {
    font-size: 36px !important;
    line-height: 42px !important;
  }
  h2 {
    font-size: 30px !important;
    line-height: 36px !important;
  }
  h3 {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  h4 {
    font-size: 16px !important;
    line-height: 22px !important;
  }
}

$icomoon-font-family: "mam-icons";
$mam-ico1: "\e900";
$mam-ico2: "\e901";
$mam-ico3: "\e902";
$mam-ico4: "\e903";
$mam-ico5: "\e904";
$mam-ico6: "\e905";
$mam-ico7: "\e906";
$mam-ico8: "\e907";
$mam-ico9: "\e908";
$mam-ico10: "\e909";
$mam-ico11: "\e90a";
$mam-ico12: "\e90b";
$mam-ico13: "\e90c";
$mam-ico14: "\e90d";
$mam-ico15: "\e90e";
$mam-ico16: "\e90f";
$mam-ico17: "\e910";
$mam-ico18: "\e911";
$mam-ico19: "\e912";
$mam-ico20: "\e913";
$mam-ico21: "\e914";
$mam-ico22: "\e915";
$mam-ico23: "\e916";
$mam-ico24: "\e917";
$mam-ico25: "\e918";
$mam-ico26: "\e919";
$mam-ico27: "\e91a";
$mam-ico28: "\e91b";
$mam-ico29: "\e91c";
$mam-ico30: "\e91d";
$mam-ico31: "\e91e";
$mam-ico32: "\e91f";
$mam-ico33: "\e920";
$mam-ico34: "\e921";
$mam-ico35: "\e922";
$mam-ico36: "\e923";
$mam-ico37: "\e924";
$mam-ico38: "\e925";
$mam-ico39: "\e926";
$mam-ico40: "\e927";
$mam-ico41: "\e928";
$mam-ico42: "\e929";
$mam-ico43: "\e92a";
$mam-ico44: "\e92b";
$mam-ico45: "\e92c";
$mam-ico46: "\e92d";
$mam-ico47: "\e92e";
$mam-ico48: "\e92f";
$mam-ico49: "\e930";
$mam-ico50: "\e931";
$mam-ico51: "\e932";
$mam-ico52: "\e933";
$mam-ico53: "\e934";
$mam-ico54: "\e935";
$mam-ico55: "\e936";
$mam-ico56: "\e937";
$mam-ico57: "\e938";
$mam-ico58: "\e939";
$mam-ico59: "\e93a";
$mam-ico60: "\e93b";
$mam-ico61: "\e93c";
$mam-ico62: "\e93d";
$mam-ico63: "\e93e";
$mam-ico64: "\e93f";
$mam-ico65: "\e940";
$mam-ico66: "\e941";
$mam-ico67: "\e942";
$mam-ico68: "\e943";
$mam-ico69: "\e944";
$mam-ico70: "\e945";
$mam-ico71: "\e946";
$mam-ico72: "\e947";
$mam-ico73: "\e948";
$mam-ico74: "\e949";
$mam-ico75: "\e94a";
$mam-ico76: "\e94b";
$mam-ico77: "\e94c";
$mam-ico78: "\e94d";
$mam-ico79: "\e94e";
$mam-ico80: "\e94f";
$mam-ico81: "\e950";
$mam-ico82: "\e951";
$mam-ico83: "\e952";
$mam-ico84: "\e953";
$mam-ico85: "\e954";
$mam-ico86: "\e955";
$mam-ico87: "\e956";
$mam-ico88: "\e957";
$mam-ico89: "\e958";
$mam-ico90: "\e959";
$mam-ico91: "\e95a";
$mam-ico92: "\e95b";
$mam-ico93: "\e95c";
$mam-ico94: "\e95d";
$mam-ico95: "\e95e";
$mam-ico96: "\e95f";
$mam-ico97: "\e960";
$mam-ico98: "\e961";
$mam-ico99: "\e962";
$mam-ico100: "\e963";
$mam-ico101: "\e964";
$mam-ico102: "\e965";
$mam-ico103: "\e966";
$mam-ico104: "\e967";
$mam-ico105: "\e968";
$mam-ico106: "\e969";
$mam-ico107: "\e96a";
$mam-ico108: "\e96b";
$mam-ico109: "\e96c";
$mam-ico110: "\e96d";
$mam-ico111: "\e96e";
$mam-ico112: "\e96f";
$mam-ico113: "\e970";
$mam-ico114: "\e971";
$mam-ico115: "\e972";
$mam-ico116: "\e973";
$mam-ico117: "\e974";
$mam-ico118: "\e975";
$mam-ico119: "\e976";
$mam-ico120: "\e977";
$mam-ico121: "\e978";
$mam-ico122: "\e979";
$mam-ico123: "\e97a";
$mam-ico124: "\e97b";
$mam-ico125: "\e97c";
$mam-ico126: "\e97d";
$mam-ico127: "\e97e";
$mam-ico128: "\e97f";
$mam-ico129: "\e980";
$mam-ico130: "\e981";
$mam-ico131: "\e982";
$mam-ico132: "\e983";
$mam-ico133: "\e984";
$mam-ico134: "\e985";
$mam-ico135: "\e986";
$mam-ico136: "\e987";
$mam-ico137: "\e988";
$mam-ico138: "\e989";
$mam-ico139: "\e98a";
$mam-ico140: "\e98b";
$mam-ico141: "\e98c";
$mam-ico142: "\e98d";
$mam-ico143: "\e98e";
$mam-ico144: "\e98f";
$mam-ico145: "\e990";
$mam-ico146: "\e991";
$mam-ico147: "\e992";
$mam-ico148: "\e993";
$mam-ico149: "\e994";
$mam-ico150: "\e995";
$mam-ico151: "\e996";
$mam-ico152: "\e997";
$mam-ico153: "\e998";
$mam-ico154: "\e999";
$mam-ico155: "\e99a";
$mam-ico156: "\e99b";
$mam-ico157: "\e99c";
$mam-ico158: "\e99d";

@function imgurl($file) {
  @return $file;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: 16px !important;
  line-height: 22px !important;
  color: $mam-dark-grey !important;
  font-weight: normal;
  hyphens: auto;
  @extend .mam-font-family;
  @media only screen and (max-width: 960px) {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

label {
  font-weight: normal;
  font-size: 16px;
}

input {
  overflow: visible;
}

input::-ms-clear {display:none;}

button, input, optgroup, select, textarea {
  margin: 0;
}

body, fieldset, figure, h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
}

a {
  color: $clink;
  font-weight: 700;
  text-decoration: none !important;
  @media only screen and (min-width:$screen-md) {
    &:hover {
      text-decoration: none !important;
      color: $chighlight !important;
    }
  }
  &:link {
    text-decoration: none !important;
  }
  &:active {
    text-decoration: none !important;
  }
  &:focus {
    text-decoration: none !important;
  }
  &:visited {
    text-decoration: none !important;
  }
}

li {
  list-style: none;
}

.form__input {
  width: 100%;
}

.form__label {
  font-size: 16px;
  line-height: 22px;
  color: $mam-dark-grey;
  font-weight: 400;
}

[hidden] {
  display: none !important;
}

.mamcom_component {
  margin-bottom: 40px;
  overflow: hidden;

  &.overflow_visible {
    overflow: visible;
  }

  @media only screen and (min-width: 960px) {
    margin-bottom: 80px;
  }
}

.mamcom_container {
  padding-bottom: 1px;
  margin: 0 16px;
  @media (min-width: $screen-xs-min) {
    margin: 0 24px;
  }
  @media (min-width: $screen-sm-min) {
    margin: 0 26px;
  }
  @media (min-width: $screen-md-min) {
    margin: 0 80px;
  }
  @media (min-width: $screen-lg-min) {
    margin: 0 auto;
    max-width: 1280px;
  }
}

.main-container {
  background-color: white;
  max-width: $screen-lg;
  min-width: 320px;
}

.mh-auto {
  margin: 0 auto;
}

.mt-1 {
  margin-top: $col-inner-padding;
}

.mt-2 {
  margin-top: $col-inner-padding * 2;
}

.mb-1 {
  margin-bottom: $col-inner-padding;
}

.mb-2 {
  margin-bottom: $col-inner-padding * 2;
  @media (min-width: $screen-md-min) {
    margin-bottom: $col-inner-padding * 3;
  }
}

.ui-page {
  background-color: $mam-white;
}


.mam-select {
  width: 100% !important;
  @extend .mam-font-family;
  color: $cbodytext !important;

  .filter-option, .dropdown-menu {
    @extend .mam-font-family;
    width: 100%;
    max-width: 100%;
  }

  button {
    &.btn {
      padding: 0 $col-inner-padding calc($col-inner-padding / 4) 0;

      &:active {
        box-shadow: none;
      }

      &.dropdown-toggle {
        border: none;
        border-radius: 0;
        background-color: transparent !important;
        outline-color: transparent !important;

        background-color: transparent !important;
        outline: none;
        color: $cbodytext;
        border-bottom: 2px solid $csuccess;
        margin-bottom: 0;

        &.bs-placeholder {
          color: $ctextinactive;
          border-bottom: 1px solid $cstroke;
          margin-bottom: 1px;
          @media only screen and (min-width:$screen-md) {
            &:hover {
              border-bottom: 2px solid $cbodytext;
              margin-bottom: 0;
            }
          }
        }

        .caret {
          color: $cbrand;
          border-width: 6px;
          margin-top: -4px;
          top: 40% !important;
        }

        .filter-option-inner {
          line-height: 22px;
          font-size: 16px;
        }

        @media (max-width:$screen-sm) {
          .filter-option-inner{
            line-height: 20px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .dropdown-menu {
    .inner {
      li.active {
        background-color: $cbrandsecondary;
      }
    }
  }

  span.deselect {
    display: none;
  }

  button:not(.bs-placeholder) + span.deselect {
    display: inline;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    right: $col-inner-padding * 1.5;

    &:before {
      color: $cbrand;
    }

    @media only screen and (min-width:$screen-md) {
      &:hover {
        &:before {
          color: $cbrandsecondary;
        }
      }
    }
  }
}

.shop-view-single {
  .visual {
    img {
      width: 100%;
      height: auto;
    }
  }

  @media (min-width: $screen-xs) {
    .left {
      width: 25%;
    }
    .right {
      width: 75%;
    }
  }

  $mam-head-font-size: 48px;
  $mam-head-line-height: 56px;
  @media (max-width: $screen-sm-min) {
    $mam-head-font-size: 45px;
    $mam-head-line-height: 52px;
  }

  .left {
    padding: 0 $col-inner-padding;
    margin: $col-inner-padding 0;
    text-align: center;

    img {
      max-width: 130px;
      @media (min-width: $screen-xs) and (max-width: $screen-sm) {
        max-width: 100%;
      }
      height: auto;
    }

    @media (max-width: $screen-sm-min) {
      text-align: left;
    }
  }

  .right {
    border-left: 2px solid $cinactive;
    margin: $col-inner-padding 0;
    padding: 0 $col-inner-padding;

    $mam-head-font-size: 48px;
    $mam-head-line-height: 56px;

    .headline, .benefit {
      font-family: $mam-head-font;
      display: inline-block;
      color: $clink;
      font-size: $mam-head-font-size;
      line-height: $mam-head-line-height;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .headline {
      margin-bottom: 42px;
      width: 100%;
    }

    .benefit {
      font-weight: bold;
      white-space: inherit;
      width: 100%;
    }

    @media (max-width: $screen-xs) {
      border: none;
    }

    @media (max-width: $screen-sm-min) {
      margin-top: 0;

      $mam-head-font-size: 45px;
      $mam-head-line-height: 52px;
      .headline, .benefit {
        font-size: $mam-head-font-size;
        line-height: $mam-head-line-height;
      }
      .headline {
        margin-bottom: 40px;
      }
      .benefit {
        white-space: normal;
      }
    }
  }

}

.ui-loader {
  display: none !important;
}

.clear-both {
  clear: both;
}