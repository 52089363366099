.mam_offer_info {
	.heading_wrapper {
		@media only screen and (min-width:$screen-sm) {
			display: flex;
		}
		.heading_content {
			border-left: 2px solid $mam-pale-grey;
			margin: 72px 0 32px 0;
			padding: 0 24px;
			min-height: 112px;
			align-items: center;
			display: flex;
			color: $cpagetitle;
			color: $cpagetitle-rgba;
			@media only screen and (max-width:$screen-sm) {
				border: none;
				margin-top: 0;
				padding: 0 10px;
				min-height: auto;
				.text-nowrap {
					white-space: initial !important;
				}
			}
		}
		.heading_logo {
			padding: 0 24px;
			margin: 72px 0 32px 0;
			align-items: center;
			display: flex;
			min-height: 112px;
			@media only screen and (max-width:$screen-sm) {
				border: none;
				padding: 0 10px;
				min-height: 0;
				margin: 0 0 16px 0;
			}
			img {
				max-height: 112px;
				max-width: 175px;
				width: 100%;
				height: auto;
				@media only screen and (max-width:$screen-sm) {
						max-height: unset;
				}
			}
		}
	}
	.keyvisual {
		padding: 0;
		margin-top: 40px;
		width: 100%;
		overflow: hidden;
		@media only screen and (max-width:$screen-sm) {
				margin-top: 0;
		}
		.keyvisual_container {
			position: relative;
			img {
					width: 100%;
					height: auto;
					line-height: 1;
			}
			.flag {
				font-size: 20px;
				line-height: 26px;
				padding: 15px 24px;
				max-width: 75%;
				background-color: $mam-highlight-blue;
				font-weight: 700;
				color: $mam-white;
				position: absolute;
				right: 0;
				top: 24px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				border-top-left-radius: 2px;
				border-bottom-left-radius: 2px;
				@media only screen and (max-width:$screen-sm) {
					font-size: 18px;
					line-height: 24px;
					padding: 12px 18px;
				}
				@media (max-width: $screen-xs) {
					display: none;
				}
			}
		}
	}
	.infobox {
		display: block;
		@media only screen and (min-width:$screen-xs) {
			display: flex;
		}
		@media only screen and (max-width:$screen-sm) {
			padding: 0;
		}
		.infobox_container {
			position: relative;
			margin-top: -40px;
			display: inline-block;
			@media only screen and (max-width:$screen-sm) {
				padding: 0 16px;
			}
			.infobox_content {
				padding: 20px;
				background: $mam-light-grey;
				.shop_button {
					text-align: center;
					margin: 15px;
					.shop_redirect_button {
						width: 100%;
					}
				}
			}
			h4 {
				font-weight: 700;
				font-size: 30px;
				line-height: 44px;
				margin-bottom: 8px;
				@media only screen and (max-width:$screen-sm) {
					margin-bottom: 10px;
					font-size: 20px;
					line-height: 26px;
				}
			}
			.miles_info, .hint_info, .terms_info {
				overflow: hidden;
				[class^="mam-"] {
					float: left;
					height: 48px;
					width: 48px;
					line-height: 48px;
					font-size: 22px;
					align-items: center;
					display: flex;
					justify-content: center;
				}
			}
			.miles_info_text, .hint_info_text, .terms_info_text {
				display: block;
				padding: 13px 13px 13px 56px;

				&.has_date {
					padding: 13px 13px 0px 56px;
				}
			}
			.hint_extra {
				display: block;
				padding: 0 0 0 56px;
				.code {
					color: $mam-accent-blue;
					font-weight: $font-weight-bold;
				}
			}
		}
	}
	.specialterms_container {
		margin-top: 40px;
		padding: 0 20px;
		@media only screen and (min-width:$screen-sm) {
			padding: 0 0 0 2.8333%;
		}
	}
}