.mam_partner_card_list_container {
    .container_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        @media only screen and (max-width:$screen-sm) {
            margin-bottom: 36px;
        }
        .heading {
            color: $mam-dark-grey;
        }
        @media only screen and (min-width:$screen-md) {
            &:hover {
                span.link_text_to_page, span.mam-ico26:before {
                    color: $mam-highlight-blue;
                }
            }
        }
        .link_to_page {
            align-items: center;
            display: flex;
            margin-left: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 120px;
            margin-left: 20px;
            text-decoration: none;
            .mam-ico26 {
                margin-left: 12px;
                align-items: center;
                display: flex;
                margin-left: 12px;
                font-weight: 700;
                font-size: 20px;
            }
            @media only screen and (max-width:$screen-sm) {
                .link_text_to_page {
                    visibility: hidden;
                }
            }
        }
    }
    .partner_card {
        list-style: none;
        position: relative;
        display: flex;
        float: left;
        .partner_card_link {
            display: block;
            margin: 2px;
            background-color: $mam-light-grey;
            width: 100%;
            @media only screen and (min-width:$screen-md) {
                &:hover {
                    @include box-shadow($box-shadow);
                }
            }
            .partner_card_logo_alignment {
                align-items: center;
                display: flex;
                height: 100%;
                justify-content: center;
                padding: 15px 0;
                .image_container {
                    text-align: center;
                    img {
                        width: 50%;
                        height: 50%;
                    }
                }
            }
        }
        .partner_card_link [class^=mamcomicon] {
            position: relative;
            top: -3px;
        }
    }
}