.mam_campaign_card_group_container {
  .heading:first-child {
    margin-bottom: 16px;
  }

  .campaign_card {
    a {
      background-color: $mam-light-grey;
      display: block;
      width: 100%;
      height: 180px;
      @media only screen and (min-width: $screen-md) {
        &:hover {
          @include box-shadow($box-shadow);
        }
      }

      .campaign_card_content {
        position: relative;
        height: 185px;

        .campaign_text_wrapper {
          position: relative;
          width: 100%;
          height: 100%;

          .campaign_text {
            position: absolute;
            line-height: 30px !important;
            font-size: 22px !important;
            bottom: 32px;
            padding: 0 80px 0 40px;
            overflow: hidden;
            max-height: 125px;
            max-width: 100%;
            display: inline-block;
            font-weight: normal;
            text-overflow: ellipsis;
            white-space: nowrap;

            strong {
              font-size: 24px !important;
              line-height: 30px !important;
              white-space: collapse;
            }

            * {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }

        .mam-ico24 {
          font-size: 32px;
          position: absolute;
          right: 22px;
          bottom: 35px;
          text-align: right;
          transform: rotate(270deg);
          height: 42px;
          width: 32px;
        }
      }
    }
  }
}