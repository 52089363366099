.category_link_list_container {
  .heading:first-child {
    margin-bottom: 16px;
  }
  margin-bottom: 144px;
  @media only screen and (max-width:$screen-sm) {
    margin-bottom: 56px;
  }
  .category_link_list {
    list-style: none;
    li {
      line-height: 22px;
      cursor: pointer;
      float:left;
      padding: 0;
      a {
        padding: 16px 12px;
        width: 100%;
        display: inline-block;
      }
      &:nth-child(3n+1) {
        a {
          padding: 16px 12px 16px 0;
        }
      }
      &:nth-child(3n) {
        a {
          padding: 16px 0 16px 12px;
        }
      }
      @media only screen and (max-width:$screen-xs) {
        &.col-xs-6 {
          width: 100%;
        }
      }
      @media only screen and (max-width:$screen-sm) {
        a {
          padding: 16px 0 !important;
        }
      }
    }
  }
}