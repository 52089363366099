.mam_heading_container {
    margin-bottom: 0;
    .heading {
        .heading_content {
            flex-direction: row;
            align-items: baseline;
            display: flex;
            justify-content: space-between;
            .heading_text {
                font-size: 45px;
                line-height: 52px;
                margin: 80px 0;
                color: $cpagetitle;
                color: $cpagetitle-rgba;
                font-weight: 300;
                max-width: 100%;
                @media only screen and (min-width: 960px) {
                    font-size: 48px;
                    line-height: 56px;
                    margin: 120px 0 72px 0;
                }
                &.small {
                    margin: 28px 0 68px 0;
                    @media only screen and (min-width: 960px) {
                        margin: 68px 0 80px 0;
                    }
                }
            }
        }
    }
}