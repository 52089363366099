// --------------------------------------------------
// Variables
// --------------------------------------------------

//
//== Typography
//

$font-family-sans-serif:    "LufthansaTextWeb", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-base:        $font-family-sans-serif !default;

//
//== Media queries breakpoints
//

// Extra small screen / phone
$screen-xs:                  599px !default;
$screen-xs-min:							 $screen-xs !default;

// Normal small screen / phone
$screen-s:                  768px !default;
$screen-s-min:							 $screen-xs !default;

// Small screen / tablet
$screen-sm:                  959px !default;
$screen-sm-min:							 $screen-sm !default;

// Medium screen / desktop
$screen-md:                  1279px !default;
$screen-md-min:							 $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  1440px !default;
$screen-lg-min:							 $screen-lg !default;

//
//== Grid system
//

//** Number of columns in the grid.
$grid-columns:              12 !default;
$grid-gutter-width:         20px !default;

//
//== Container sizes
//

// Small screen / tablet
$container-tablet:             (960px + $grid-gutter-width) !default;

// Medium screen / desktop
$container-desktop:            (1280px + $grid-gutter-width) !default;

// Large screen / wide desktop
$container-large-desktop:      (1440px + $grid-gutter-width) !default;