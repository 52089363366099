.mam_carousel_container {
    @keyframes carousel-indicator-load {
        0% {
            width: 0;
        }
        100% {
            width: 48px;
        }
    }

    .slider-container {
        min-height: 800px;
    }

    .carousel {
        .slick-list {
            padding-left: 5px !important;
            @media only screen and (max-width:$screen-xs) {
                padding: 0 10px 0 5px !important;
                .carousel-item {
                    margin: 0 10px 0 0;
                }
            }
        }
        .slick-slide {
            padding-bottom: 10px;

            .mam-slide {
                width: 1200px;
                @media only screen and (max-width:$screen-lg) {
                    width: 1000px;
                }
                @media only screen and (max-width:$screen-md) {
                    width: calc(100vw - 250px);
                }
                @media only screen and (max-width:$screen-sm) {
                    width: calc(80vw - 200px);
                }
                @media only screen and (max-width:860px) {
                    width: calc(100vw - 250px);
                }
                @media only screen and (max-width:830px) {
                    width: calc(100vw - 200px);
                }
                @media only screen and (max-width:$screen-s) {
                    width: calc(100vw - 150px);
                }
                @media only screen and (max-width:$screen-xs) {
                    width: calc(100vw - 90px);
                }
                height: auto;
                overflow: hidden;
                margin: 0 30px 0 0;
                cursor: pointer;
                &:hover {
                    @include box-shadow($box-shadow);
                }
                .center-block {
                    overflow: hidden;
                    position: relative;
                    img {
                        position: relative;
                        top: 0;
                        height: 512px;
                        @media only screen and (max-width:$screen-md) {
                            height: 525px;
                            left: -25%;
                        }
                        @media only screen and (max-width:$screen-xs) {
                            height: 260px;
                        }
                        @media only screen and (max-width:500px) {
                            left: -50%;
                        }
                    }

                    .flag {
                        position: absolute;
                        top: 24px;
                        right: 0;
                        font-size: 20px;
                        line-height: 26px;
                        padding: 15px 24px;
                        max-width: 75%;
                        background-color: $mam-highlight-blue;
                        font-weight: 700;
                        color: $mam-white;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        border-top-left-radius: 2px;
                        border-bottom-left-radius: 2px;
                        @media only screen and (max-width:$screen-sm) {
                            font-size: 18px;
                            line-height: 24px;
                            padding: 12px 18px;
                        }
                        @media (max-width: $screen-xs) {
                            display: none;
                        }
                    }
                }
                .card-img-overlay {
                    position: relative;
                    .left {
                        padding: 0 40px 0 24px !important;
                        margin: 24px 0;
                        text-align: center;
                        img {
                            max-width: 130px;
                            height: auto;
                        }
                        @media only screen and (max-width:$screen-lg) {
                            padding-left: 32px;
                            text-align: left;
                        }
                    }
                    .right {
                        border-left: 2px solid $mam-pale-grey;
                        margin: 40px 0;
                        padding: 0 24px;
                        color: $clink;
                        overflow: hidden;
                        font-family: "LufthansaHeadWeb", "Helvetica Neue", Helvetica, Arial, sans-serif;
                        text-overflow: ellipsis;
                        .headline {
                            margin-bottom: 42px;
                            width: 100%;
                            display: inline-block;
                            font-size: 36px;
                            line-height: 42px;
                            font-weight: normal;
                        }
                        .benefit {
                            font-weight: bold;
                            white-space: inherit;
                            width: 100%;
                            display: inline-block;
                            font-size: 36px;
                            line-height: 42px;
                        }
                        @media only screen and (max-width:$screen-sm) {
                            padding: 0 32px;
                            border: none;
                        }
                    }
                }
            }
        }

        .slick-arrow {
            z-index: 5;
            top: 33%;

            @media only screen and (max-width:$screen-s) {
                display: none !important;
            }

            .carousel-control-prev, .carousel-control-next {
                z-index: 5;
                border-radius: 50%;
                cursor: pointer;
                display: block;
                width: 58px;
                height: 58px;
                background-color: $mam-pale-grey;
                text-align: center;
                @media only screen and (max-width:$screen-md) {
                    width: 32px;
                    height: 32px;
                }
                span {
                    font-size: 20px;
                }
            }
        }

        .slick-dots {
            top: 450px;
            bottom: auto;
            z-index: 1001;
            li {
                width: 48px;
                height: 4px;
                background-color: $mam-white;
                border-radius: 0;
                overflow: hidden;
                box-sizing: content-box;
                flex: 0 1 auto;
                padding: 0;
                text-indent: -999px;
                cursor: pointer;
                opacity: 0.5;
                &.slick-active {
                    opacity: 1;
                    background-color: $cinactive;
                    button {
                        animation: carousel-indicator-load 10s linear;
                    }
                }
                button {
                    border: none;
                    margin: 0;
                    padding: 0;
                    width: 0;
                    height: 4px;
                    background-color: $chighlight;
                    &:before {
                        display: none;
                    }
                }
            }
        }
        @media only screen and (min-width:$screen-md) {
            &:hover {
                .slick-dots {
                    li {
                        &.slick-active {
                            button {
                                animation-play-state: paused;
                            }
                        }
                    }
                }
            }
        }
        @media only screen and (max-width:$screen-xs) {
            .slick-dots {
                top: 210px;
                li {
                    width: 20px;
                }
            }
            .carousel-control-prev, .carousel-control-next {
                display: none !important;
            }
        }
    }
}
