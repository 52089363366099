.mam_campaign_info {
	.keyvisual {
		padding: 0;
		margin-top: 40px;
		width: 100%;
		overflow: hidden;
		@media only screen and (max-width:$screen-sm) {
				margin-top: 0;
		}
		.keyvisual_container {
			position: relative;
			img {
				position: relative;
				top: 0;
				height: 512px;
				@media only screen and (max-width:$screen-sm) {
					left: -25%;
				}
				@media only screen and (max-width:$screen-xs) {
					height: 260px;
				}
			}
		}
	}
	.infobox {
		display: block;
		.infobox_header {
			padding: 30px 0 0 0;
		}
		.infobox_container {
			.infobox_content {
				padding: 30px 0;
			}
		}
	}
}