.mam_search_container {
	.search_header {
		margin-bottom: 32px;
	}
	.search_form_wrapper {
		padding: 30px 40px 30px 40px;
		position: relative;
		background-color: $cbodybg;
		.form_elements {
			display: flex;
			flex-direction: column;
			width: 100%;
			@media only screen and (min-width: $screen-md) {
				flex-direction: row;
			}
			.form_element_wrapper {
				flex: 1 0 auto;
				position: relative;
				@media only screen and (min-width: $screen-md) {
					padding-right: 24px;
				}
				.searchtrigger_input {
					position: relative;
					width: 100%;
					padding: 0;
					margin-bottom: 28px;
					.form_textfield {
						height: 48px;
						margin-top: 0;
						display: inline-flex;
						position: relative;
						align-items: flex-end;
						box-shadow: inset 0 -1px 0 0 $mam-medium-grey;
						margin-bottom: 0;
						width: 100%;
						font-size: inherit;
						.search_autocomplete {
							width: 100%;
							border: none;
							border-radius: 0;
							line-height: 20px;
							background: none;
							padding: 0 40px 8px 0;
							font-size: 16px;
							line-height: 20px;
							color: $mam-dark-grey;
							box-shadow: none;
						}
						.form_textfield_icon_cleaner {
							position: absolute;
							bottom: 8px;
							right: 4px;
							cursor: pointer;
							.mam-ico8 {
								height: 22px;
								width: 22px;
								font-size: 18px;
								align-self: center;
								display: inline-flex;
								position: relative;
							}
						}
					}
					.autocomplete {
						top: 44px;
						z-index: 1000;
						width: 100% !important;
						@media only screen and (min-width: $screen-md) {
							width: 50% !important;
						}
						img {
							height: 50px;
							width: auto;
						}
						.autocomplete-result {
							border: none;
							border-radius: unset;
							padding: 0 20px 20px 20px !important;
							&:first-child {
								padding-top: 20px !important;
							}
							.partner_search {
								.mam-ico28 {
									padding: 10px;
								}
								.text-info {
									color: $mam-accent-blue !important;
								}
							}
						}
					}
				}
			}
			.searchtrigger_search {
				width: 100%;
				height: auto;
				padding: 15px 0 17px 0;
				position: relative;
				margin-bottom: 20px;
				@media only screen and (min-width: $screen-md) {
					padding: 0 48px;
					top: 8px;
					margin-bottom: 0;
					width: auto;
					height: 40px;
				}
			}
		}

		.mam-select-chips {
			position: relative;
			display: inline-block;
			.subheading {
				margin-bottom: 15px;
			}
			.chip {
				background: $cinactive;
				border-radius: 18px;
				cursor: pointer;
				display: block;
				float: left;
				margin-bottom: 8px;
				margin-right: 8px;
				overflow: hidden;
				padding: 6px 12px;
				position: relative;
				max-width: 100%;
				text-overflow: ellipsis;
				height: 35px;
				overflow: hidden;
				white-space: nowrap;
				-webkit-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				will-change: transform, opacity;
				text-shadow: none;
				@media only screen and (min-width: $screen-md) {
					.chip {
						padding: 5px 12px;
					}
				}
				&.chip--active {
					background: $chighlight !important;
					color: $mam-white;
				}
				&:first-child {
					margin-left: 0 !important;
				}
			}
		}
	}
}