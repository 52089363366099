.mam_overlay_container {
	position: absolute;
	top: 0;
	left: 0;
	background-color: $overlay;
	height: 100%;
	width: 100%;
	display: none;
	z-index: 999;
	&.active {
		display: block;
	}
	.heading_text {
		margin-bottom: 30px;
		@media only screen and (max-width:$screen-sm) {
			.text-nowrap {
				white-space: break-spaces !important;
			}
		}
	}
	.overlay_box {
		background: $mam-white;
		z-index: 1000;
		padding-bottom: 60px;
		position: absolute;
		border: 1px solid $mam-black;
		width: 100%;
		@media only screen and (max-width:$screen-sm) {
			padding: 15px 15px 60px 15px;
		}
		.icon_container {
			text-align: right;
			.icon {
				padding: 30px;
				font-size: 35px;
				display: inline-block;
				transform: rotate(135deg);
				&:hover {
					cursor: pointer;
				}
			}
		}
		.servicecard_container {
			position: relative;
			padding: 30px 30px 0 30px;
			margin-bottom: 30px;
			background-color: $mam-light-grey;
			overflow: hidden;
			.heading {
				h3, h4 {
					font-size: 24px;
					line-height: 30px;
					margin-bottom: 15px;
				}
				p {
					margin-bottom: 15px;
				}
			}
			.form_elements {
				display: flex;
				flex-direction: column;
				.form_element_wrapper {
					flex: 1 0 auto;
					align-items: flex-start;
					display: flex;
					position: relative;
					.servicecard_input {
						position: relative;
						width: 100%;
						padding: 0;
						margin-bottom: 32px;
						.form_textfield {
							height: 48px;
							margin-top: 0;
							display: inline-flex;
							position: relative;
							align-items: flex-end;
							box-shadow: inset 0 -1px 0 0 $mam-medium-grey;
							margin-bottom: 0;
							width: 100%;
							font-size: inherit;
							will-change: color;
							.form_textfield_input {
								width: 100%;
								border: none;
								border-radius: 0;
								line-height: 20px;
								filter: alpha(opacity=0);
								opacity: 0;
								background: none;
								font-size: inherit;
								padding: 0 40px 4px 0;
								&:focus {
									outline: none;
								}
							}
							.form_textfield_label {
								width: 100%;
								position: absolute;
								font-weight: 400;
								bottom: 4px;
								left: 0;
								margin: 0;
								cursor: text;
							}
							.form_textfield_icon_cleaner {
								filter: alpha(opacity=0);
								opacity: 0;
								position: absolute;
								bottom: 4px;
								right: 10px;
								cursor: pointer;
								.mam-ico11, .mam-ico12 {
									height: 28px;
									width: 28px;
									font-size: 28px;
									align-self: center;
									display: inline-flex;
									position: relative;
								}
								.mam-ico11:before {
									color: $mam-success-green;
								}
								.mam-ico12:before {
									color: $mam-error-red;
								}
							}
							&.focus {
								box-shadow: inset 0 -2px 0 0 $mam-highlight-blue;
								.form_textfield_input {
									font-size: 16px;
									line-height: 20px;
									color: $mam-dark-grey;
								}
								.form_textfield_label {
									color: $mam-highlight-blue;
									font-size: 16px;
								}
							}
							&.filled {
								box-shadow: inset 0 -1px 0 0 $mam-medium-grey;
								.form_textfield_label {
									color: $mam-dim-grey-darker;
								}
							}
							&.correct {
								box-shadow: inset 0 -2px 0 0 $mam-success-green;
							}
							&.incorrect {
								box-shadow: inset 0 -2px 0 0 $mam-error-red;
							}
						}
					}
				}
			}
		}
		.button_voucher_container {
			margin-bottom: 30px;
			@media only screen and (min-width:$screen-sm) {
				display: flex;
				line-height: 30px;
			}
			.voucher_text {
				padding: 15px 0 30px 0;
				@media only screen and (min-width:$screen-sm) {
					padding: 15px 0 0 0;
				}
				.code {
					font-weight: bold;
					color: $mam-accent-blue;
				}
			}
			.voucher_button {
				margin-bottom: 15px;
			}
			button {
				width: 100%;
			}
		}
		.terms_container {
			margin-bottom: 30px;
			.hint {
				margin-bottom: 30px;
			}
			.alert_box {
				padding: 30px;
				background-color: $mam-light-highlight-blue;
				margin-bottom: 30px;
				.mam-ico31 {
					padding-right: 15px;
					font-size: 22px;
					vertical-align: bottom;
				}
			}
			.conditions {
				h3 {
					margin-bottom: 30px;
				}
			}
		}
	}
}