.mam_how_to_container {
	.carousel {
		.slick-list {
			padding: 2px 15px 2px 2px !important;
			.slick-slide {
				.item {
					width: 100%;
				}
				position: relative;
				width: 640px;
				height: auto;
				overflow: hidden;
				padding: 0;
				margin: 0 15px 0 0;
				cursor: pointer;
				@include box-shadow($box-shadow-inactive);
				.item > img {
					height: 30px;
					display: none;
				}
				@media only screen and (min-width:$screen-sm) {
					width: 100% !important;
					@include box-shadow($box-shadow-none);
					.item > img {
						display: block;
						margin: 0 0 0 85px;
					}
				}
				.how_to_content {
					position: relative;
					padding: 30px;
					.how_to_icon {
						border-radius: 50%;
						height: 150px;
						width: 150px;
						background-color: $mam-light-grey;
						position: relative;
						img {
							position: absolute;
							bottom: 15%;
							right: 20%;
							width: 30%;
							height: 30%;
							z-index: 222;
							background-color: $mam-white;
							border-radius: 50%;
							padding: 5px;
							&:first-child {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								z-index: 111;
								width: 60%;
								height: 60%;
								background-color: transparent;
								border-radius: initial;
							}
						}
					}
					.how_to_textbox {
						width: auto;
						height: auto;
						overflow: hidden;
						position: relative;
						margin-top: 30px;
						padding-right: 30px;
						@media only screen and (min-width:$screen-sm) {
							position: absolute;
							left: 220px;
							top: 50%;
							transform: translate(0px, -100%);
						}
						.headline {
							font-size: 22px;
							display: inline-block;
						}
						.infotext {
							font-size: 16px;
							display: inline-block;
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
	.slick-dots {
		position: relative;
		margin: 0 0 40px 0;
		li {
			&:hover {
				color: green !important;
			}
			button {
				&:hover {
					color: green !important;
				}
				&:before {
					font-size: 20px;
					color: $mam-lh-blue;
					&:hover {
						color: green !important;
					}	
				}
			}
			&.slick-active {
				button {
					&:before {
						color: $mam-lh-blue;
					}
				}
			}
		}
	}
	.shop_button {
		text-align: center;
		padding-top: 30px;
	}
}