.partner_listing_container {
  margin-bottom: 56px;
  @media (min-width: $screen-md) {
    margin-bottom: 96px;
  }
  .list-head {
    padding-bottom:$col-inner-padding * 1.5;
    margin-bottom:32px;
    border-bottom: 1px solid $cstroke;
  }
  .sort {
    padding-bottom: 15px;
    .box {
      display: flex;
      flex-direction: row;
    }
    .result {
      float: left;
      width: 100%;
      line-height: 40px;
      padding-left: 20px;
      order: 2;
      @media only screen and (min-width:$screen-md) {
        order: 1;
        width: 50%;
      }
    }
    .select {
      float: right;
      width: 100%;
      text-align: right;
      line-height: 40px;
      order: 1;
      text-align: left;
      @media only screen and (min-width:$screen-md) {
        order: 2;
        width: 50%;
        text-align: right;
      }
      .text {
        padding-left: 20px;
      }
      .dropdown {
        display: inline-block;
        width: auto;
        .btn {
          border: none;
          font-weight: bold;
        }
        .dropdown-menu {
          padding: 0;
          background-color: white;
          border: none;
          border-radius: 2px;
          color: $mam-dark-grey;
          @include box-shadow($box-shadow);
          .dropdown-item {
            color: $mam-dark-grey;
            background-color: $mam-white;
            &:hover {
              color: $mam-dark-grey !important;
              background-color: $cbodybg;
            }
          }
        }
      }
    }
  }
  .logos {
    .button_container {
      margin-bottom: 16px;
      height: 140px;
      &.col-xs-6 {
        width: 50%;
      }
      @media only screen and (min-width:$screen-md) {
        &.col-xs-6 {
          width: 33.33333%;
        }
      }
      @media (min-width: $screen-md) {
        margin-bottom: 64px;
        height: 220px;
      }
      a, div.inactive {
        @include box-shadow($box-shadow-inactive);
        overflow: hidden;
        display: block;
        font-size: 14px;
        height: 140px;
        @media (min-width: $screen-md) {
          font-size: 24px;
          height: 220px;
        }
        padding: 0;
        @media only screen and (min-width:$screen-md) {
          &:hover {
            box-shadow: $box-shadow;
            background-color: transparent;
          }
        }
        .logo {
          text-align: center;
          padding-top: 32px;
          height: 77px;
          position: relative;
          @media (min-width: $screen-md) {
            padding-top: 48px;
            height: 148px;
          }
          img {
            max-width: 100%;
            max-height: 52px;
            width: auto;
            @media (min-width: $screen-md) {
              max-height: 110px;
            }
          }
          .icon {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            @media (min-width: $screen-md) {
              font-size: 30px;
            }
            & span:before {
              color: $mam-highlight-blue;
            }
          }
        }
        .miles {
          height: 28px;
          line-height: 28px;
          max-width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: center;
          padding: 0 8px;
          margin-top: 20px;
          @media (min-width: $screen-md) {
            height: 40px;
            line-height: 40px;
            padding: 0 24px;
          }
          color: $mam-dark-grey;
          text-align: center;
          text-shadow: none;
          font-size: 12px;
          @media (min-width: $screen-md) {
            font-size: 20px;
          }
          &.campaign {
            color: $mam-highlight-blue;
          }
        }
      }
    }
  }
  .footnote {
    .campaign, .freeshipping {
      width: auto;
      float: left;
      padding-left: 20px;
      line-height: 40px;
      span {
        margin-right: 5px;
        font-size: 20px;
        vertical-align: middle;
        &:before {
          color: $mam-highlight-blue;
        }
      }
    }
  }
}