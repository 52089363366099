@keyframes transform-open {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(135deg);
    }
}
@keyframes transform-close {
    from {
        transform: rotate(135deg);
    }
    to {
        transform: rotate(0deg);
    }
}
.accordion {
    .accordion-header {
        .heading {
            padding: 32px 0;
            border-bottom: 1px solid $mam-pale-grey;
        }
    }
    .accordion-item {
        overflow: hidden;
        border-top: 0;
        border-bottom: 1px solid $mam-pale-grey;
        .accordion-header {
            overflow: hidden;
            .accordion-button {
                display: inline-block;
                background: none;
                box-shadow: none;
                padding: 16px 0;
                cursor: pointer;
                color: $mam-highlight-blue;
                @media only screen and (max-width: $screen-sm) {
                    padding: 13px 0;
                }
                &.collapsed {
                    color: $clink-accordion;
                    span.icon {
                        animation: transform-close 250ms 1 linear;
                        transform: rotate(0deg);
                    }
                }
                span.icon {
                    float: right;
                    font-size: 24px;
                    line-height: 22px;
                    animation: transform-open 250ms  linear;
                    transform: rotate(135deg);
                }
            }
            h4.accordion-boxheadline {
                float: left;
                font-size: 16px;
                line-height: 22px;
                font-weight: 700;
                max-width: 80%;
            }
        }
    }
}