.mam_chip_menu_container {
	margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 2px solid $mam-medium-grey;
	.container_header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 32px;
			@media only screen and (max-width:$screen-sm) {
					margin-bottom: 36px;
			}
			.heading {
					color: $mam-dark-grey;
			}
			@media only screen and (min-width:$screen-md) {
				&:hover {
						span.link_text_to_page, span.mam-ico26:before {
								color: $mam-highlight-blue;
						}
				}
			}
			.link_to_page {
					align-items: center;
					display: flex;
					margin-left: auto;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					min-width: 120px;
					margin-left: 20px;
					text-decoration: none;
					.mam-ico26 {
							margin-left: 12px;
							align-items: center;
							display: flex;
							margin-left: 12px;
							font-weight: 700;
							font-size: 20px;
					}
					@media only screen and (max-width:$screen-sm) {
							.link_text_to_page {
									visibility: hidden;
							}
					}
			}
	}
	.chip {
		background: $clistdivider;
		background: $clistdivider-rgba;
		border-radius: 18px;
		cursor: pointer;
		display: block;
		float: left;
		margin-bottom: 8px;
		margin-right: 8px;
		overflow: hidden;
		padding: 6px 12px;
		position: relative;
		max-width: 100%;
		text-overflow: ellipsis;
		height: 35px;
		overflow: hidden;
		white-space: nowrap;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		will-change: transform, opacity;
		text-shadow: none;
		@media only screen and (min-width:$screen-md) {
			padding: 5px 12px;
		}
	}
	.chip--active {
		background: #3262cf !important;
		color: #fff;
	}
	.chip:first-child {
		margin-left: 0 !important;
	}
}