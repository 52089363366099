.mam_loadingspinner_container {
  @keyframes mamcomloadingspinnerrotate {
    to {
      transform: rotate(1turn);
    }
  }

  @keyframes mamcomloadingspinnerdash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    to {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  .loadingSpinner {
    animation: mamcomloadingspinnerrotate 2s linear infinite;
    height: 64px;
    margin: 40px auto;
    width: 64px;
    //display: none;
  }

  .loadingSpinner .loadingSpinner_circleWrapper {
    animation: mamcomloadingspinnerdash 1.5s ease-in-out infinite;
    stroke: $cpagetitle;
    stroke: $cpagetitle-rgba;
    stroke-linecap: round;
  }

  .button .loadingSpinner {
    height: 28px;
    margin: 0;
    width: 28px;
  }

  .button .loadingSpinner .loadingSpinner_circleWrapper {
    stroke: #fff;
  }

  _:-ms-fullscreen, :root .loadingSpinner {
    width: 64px;
    height: 64px;
    margin: 40px auto;
    color: $cpagetitle;
    color: $cpagetitle-rgba;
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: inline-block;
      border: 5px solid currentColor;
      border-bottom-color: transparent;
      border-radius: 100%;
      background: transparent;
    }
    .loadingSpinner_circleWrapper {
      display: none;
      animation: none;
    }
  }

  @keyframes ball-clip-rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}