@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$font-path}/#{$icomoon-font-family}.eot?m4e6zj');
  src: url('#{$font-path}/#{$icomoon-font-family}.eot?m4e6zj#iefix') format('embedded-opentype'),
  url('#{$font-path}/#{$icomoon-font-family}.ttf?m4e6zj') format('truetype'),
  url('#{$font-path}/#{$icomoon-font-family}.woff?m4e6zj') format('woff'),
  url('#{$font-path}/#{$icomoon-font-family}.svg?m4e6zj##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LufthansaHeadWeb";
  src: url("#{$font-path}/LufthansaHeadWeb-Bold.eot?#iefix");
  src: url("#{$font-path}/LufthansaHeadWeb-Bold.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}/LufthansaHeadWeb-Bold.woff") format("woff"),
  url("#{$font-path}/LufthansaHeadWeb-Bold.ttf") format("truetype"),
  url("#{$font-path}/LufthansaHeadWeb-Bold.svg") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "LufthansaHeadWeb";
  src: url("#{$font-path}/LufthansaHeadWeb-Light.eot?#iefix");
  src: url("#{$font-path}/LufthansaHeadWeb-Light.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}/LufthansaHeadWeb-Light.woff") format("woff"),
  url("#{$font-path}/LufthansaHeadWeb-Light.ttf") format("truetype"),
  url("#{$font-path}/LufthansaHeadWeb-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LufthansaTextWeb";
  src: url("#{$font-path}/LufthansaTextWeb-Bold.eot?#iefix");
  src: url("#{$font-path}/LufthansaTextWeb-Bold.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}/LufthansaTextWeb-Bold.woff") format("woff"),
  url("#{$font-path}/LufthansaTextWeb-Bold.ttf") format("truetype"),
  url("#{$font-path}/LufthansaTextWeb-Bold.svg") format("svg");
  font-weight: Bold;
  font-style: normal;
}

@font-face {
  font-family: "LufthansaTextWeb";
  src: url("#{$font-path}/LufthansaTextWeb-Regular.eot?#iefix");
  src: url("#{$font-path}/LufthansaTextWeb-Regular.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}/LufthansaTextWeb-Regular.woff") format("woff"),
  url("#{$font-path}/LufthansaTextWeb-Regular.ttf") format("truetype"),
  url("#{$font-path}/LufthansaTextWeb-Regular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "LufthansaTextWeb";
  src: url("#{$font-path}/LufthansaTextWeb-Light.eot?#iefix");
  src: url("#{$font-path}/LufthansaTextWeb-Light.eot?#iefix") format("embedded-opentype"),
  url("#{$font-path}/LufthansaTextWeb-Light.woff") format("woff"),
  url("#{$font-path}/LufthansaTextWeb-Light.ttf") format("truetype"),
  url("#{$font-path}/LufthansaTextWeb-Light.svg") format("svg");
  font-weight: 100;
  font-style: normal;
}

span[class^="mam-"], span[class*=" mam-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    color: $cbrand;
  }
}

.mam-ico1 {
  &:before {
    content: $mam-ico1;
  }
}

.mam-ico2 {
  &:before {
    content: $mam-ico2;
  }
}

.mam-ico3 {
  &:before {
    content: $mam-ico3;
  }
}

.mam-ico4 {
  &:before {
    content: $mam-ico4;
  }
}

.mam-ico5 {
  &:before {
    content: $mam-ico5;
  }
}

.mam-ico6 {
  &:before {
    content: $mam-ico6;
  }
}

.mam-ico7 {
  &:before {
    content: $mam-ico7;
  }
}

.mam-ico8 {
  &:before {
    content: $mam-ico8;
  }
}

.mam-ico9 {
  &:before {
    content: $mam-ico9;
  }
}

.mam-ico10 {
  &:before {
    content: $mam-ico10;
  }
}

.mam-ico11 {
  &:before {
    content: $mam-ico11;

  }
}

.mam-ico12 {
  &:before {
    content: $mam-ico12;

  }
}

.mam-ico13 {
  &:before {
    content: $mam-ico13;
  }
}

.mam-ico14 {
  &:before {
    content: $mam-ico14;
  }
}

.mam-ico15 {
  &:before {
    content: $mam-ico15;
  }
}

.mam-ico16 {
  &:before {
    content: $mam-ico16;
  }
}

.mam-ico17 {
  &:before {
    content: $mam-ico17;
  }
}

.mam-ico18 {
  &:before {
    content: $mam-ico18;
  }
}

.mam-ico19 {
  &:before {
    content: $mam-ico19;
  }
}

.mam-ico20 {
  &:before {
    content: $mam-ico20;
  }
}

.mam-ico21 {
  &:before {
    content: $mam-ico21;
  }
}

.mam-ico22 {
  &:before {
    content: $mam-ico22;
  }
}

.mam-ico23 {
  &:before {
    content: $mam-ico23;
  }
}

.mam-ico24 {
  &:before {
    content: $mam-ico24;
  }
}

.mam-ico25 {
  &:before {
    content: $mam-ico25;
  }
}

.mam-ico26 {
  &:before {
    content: $mam-ico26;
  }
}

.mam-ico27 {
  &:before {
    content: $mam-ico27;
  }
}

.mam-ico28 {
  &:before {
    content: $mam-ico28;
  }
}

.mam-ico29 {
  &:before {
    content: $mam-ico29;
  }
}

.mam-ico30 {
  &:before {
    content: $mam-ico30;
  }
}

.mam-ico31 {
  &:before {
    content: $mam-ico31;
  }
}

.mam-ico32 {
  &:before {
    content: $mam-ico32;
  }
}

.mam-ico33 {
  &:before {
    content: $mam-ico33;
  }
}

.mam-ico34 {
  &:before {
    content: $mam-ico34;
  }
}

.mam-ico35 {
  &:before {
    content: $mam-ico35;
  }
}

.mam-ico36 {
  &:before {
    content: $mam-ico36;
  }
}

.mam-ico37 {
  &:before {
    content: $mam-ico37;
  }
}

.mam-ico38 {
  &:before {
    content: $mam-ico38;
  }
}

.mam-ico39 {
  &:before {
    content: $mam-ico39;
  }
}

.mam-ico40 {
  &:before {
    content: $mam-ico40;
  }
}

.mam-ico41 {
  &:before {
    content: $mam-ico41;
  }
}

.mam-ico42 {
  &:before {
    content: $mam-ico42;
  }
}

.mam-ico43 {
  &:before {
    content: $mam-ico43;
  }
}

.mam-ico44 {
  &:before {
    content: $mam-ico44;
  }
}

.mam-ico45 {
  &:before {
    content: $mam-ico45;
  }
}

.mam-ico46 {
  &:before {
    content: $mam-ico46;
  }
}

.mam-ico47 {
  &:before {
    content: $mam-ico47;
  }
}

.mam-ico48 {
  &:before {
    content: $mam-ico48;
  }
}

.mam-ico49 {
  &:before {
    content: $mam-ico49;
  }
}

.mam-ico50 {
  &:before {
    content: $mam-ico50;
  }
}

.mam-ico51 {
  &:before {
    content: $mam-ico51;
  }
}

.mam-ico52 {
  &:before {
    content: $mam-ico52;
  }
}

.mam-ico53 {
  &:before {
    content: $mam-ico53;
  }
}

.mam-ico54 {
  &:before {
    content: $mam-ico54;
  }
}

.mam-ico55 {
  &:before {
    content: $mam-ico55;
  }
}

.mam-ico56 {
  &:before {
    content: $mam-ico56;
  }
}

.mam-ico57 {
  &:before {
    content: $mam-ico57;
  }
}

.mam-ico58 {
  &:before {
    content: $mam-ico58;
  }
}

.mam-ico59 {
  &:before {
    content: $mam-ico59;
  }
}

.mam-ico60 {
  &:before {
    content: $mam-ico60;
  }
}

.mam-ico61 {
  &:before {
    content: $mam-ico61;
  }
}

.mam-ico62 {
  &:before {
    content: $mam-ico62;
  }
}

.mam-ico63 {
  &:before {
    content: $mam-ico63;
  }
}

.mam-ico64 {
  &:before {
    content: $mam-ico64;
  }
}

.mam-ico65 {
  &:before {
    content: $mam-ico65;
  }
}

.mam-ico66 {
  &:before {
    content: $mam-ico66;
  }
}

.mam-ico67 {
  &:before {
    content: $mam-ico67;
  }
}

.mam-ico68 {
  &:before {
    content: $mam-ico68;
  }
}

.mam-ico69 {
  &:before {
    content: $mam-ico69;
  }
}

.mam-ico70 {
  &:before {
    content: $mam-ico70;
  }
}

.mam-ico71 {
  &:before {
    content: $mam-ico71;
  }
}

.mam-ico72 {
  &:before {
    content: $mam-ico72;
  }
}

.mam-ico73 {
  &:before {
    content: $mam-ico73;
  }
}

.mam-ico74 {
  &:before {
    content: $mam-ico74;
  }
}

.mam-ico75 {
  &:before {
    content: $mam-ico75;
  }
}

.mam-ico76 {
  &:before {
    content: $mam-ico76;
  }
}

.mam-ico77 {
  &:before {
    content: $mam-ico77;
  }
}

.mam-ico78 {
  &:before {
    content: $mam-ico78;
  }
}

.mam-ico79 {
  &:before {
    content: $mam-ico79;
  }
}

.mam-ico80 {
  &:before {
    content: $mam-ico80;
  }
}

.mam-ico81 {
  &:before {
    content: $mam-ico81;
  }
}

.mam-ico82 {
  &:before {
    content: $mam-ico82;
  }
}

.mam-ico83 {
  &:before {
    content: $mam-ico83;
  }
}

.mam-ico84 {
  &:before {
    content: $mam-ico84;
  }
}

.mam-ico85 {
  &:before {
    content: $mam-ico85;
  }
}

.mam-ico86 {
  &:before {
    content: $mam-ico86;
  }
}

.mam-ico87 {
  &:before {
    content: $mam-ico87;
  }
}

.mam-ico88 {
  &:before {
    content: $mam-ico88;
  }
}

.mam-ico89 {
  &:before {
    content: $mam-ico89;
  }
}

.mam-ico90 {
  &:before {
    content: $mam-ico90;
  }
}

.mam-ico91 {
  &:before {
    content: $mam-ico91;
  }
}

.mam-ico92 {
  &:before {
    content: $mam-ico92;
  }
}

.mam-ico93 {
  &:before {
    content: $mam-ico93;
  }
}

.mam-ico94 {
  &:before {
    content: $mam-ico94;
  }
}

.mam-ico95 {
  &:before {
    content: $mam-ico95;
  }
}

.mam-ico96 {
  &:before {
    content: $mam-ico96;
  }
}

.mam-ico97 {
  &:before {
    content: $mam-ico97;
  }
}

.mam-ico98 {
  &:before {
    content: $mam-ico98;
  }
}

.mam-ico99 {
  &:before {
    content: $mam-ico99;
  }
}

.mam-ico100 {
  &:before {
    content: $mam-ico100;
  }
}

.mam-ico101 {
  &:before {
    content: $mam-ico101;
  }
}

.mam-ico102 {
  &:before {
    content: $mam-ico102;
  }
}

.mam-ico103 {
  &:before {
    content: $mam-ico103;
  }
}

.mam-ico104 {
  &:before {
    content: $mam-ico104;
  }
}

.mam-ico105 {
  &:before {
    content: $mam-ico105;
  }
}

.mam-ico106 {
  &:before {
    content: $mam-ico106;
  }
}

.mam-ico107 {
  &:before {
    content: $mam-ico107;
  }
}

.mam-ico108 {
  &:before {
    content: $mam-ico108;
  }
}

.mam-ico109 {
  &:before {
    content: $mam-ico109;
  }
}

.mam-ico110 {
  &:before {
    content: $mam-ico110;
  }
}

.mam-ico111 {
  &:before {
    content: $mam-ico111;
  }
}

.mam-ico112 {
  &:before {
    content: $mam-ico112;
  }
}

.mam-ico113 {
  &:before {
    content: $mam-ico113;
  }
}

.mam-ico114 {
  &:before {
    content: $mam-ico114;
  }
}

.mam-ico115 {
  &:before {
    content: $mam-ico115;
  }
}

.mam-ico116 {
  &:before {
    content: $mam-ico116;
  }
}

.mam-ico117 {
  &:before {
    content: $mam-ico117;
  }
}

.mam-ico118 {
  &:before {
    content: $mam-ico118;
  }
}

.mam-ico119 {
  &:before {
    content: $mam-ico119;
  }
}

.mam-ico120 {
  &:before {
    content: $mam-ico120;
  }
}

.mam-ico121 {
  &:before {
    content: $mam-ico121;
  }
}

.mam-ico122 {
  &:before {
    content: $mam-ico122;
  }
}

.mam-ico123 {
  &:before {
    content: $mam-ico123;
  }
}

.mam-ico124 {
  &:before {
    content: $mam-ico124;
  }
}

.mam-ico125 {
  &:before {
    content: $mam-ico125;
  }
}

.mam-ico126 {
  &:before {
    content: $mam-ico126;
  }
}

.mam-ico127 {
  &:before {
    content: $mam-ico127;
  }
}

.mam-ico128 {
  &:before {
    content: $mam-ico128;
  }
}

.mam-ico129 {
  &:before {
    content: $mam-ico129;
  }
}

.mam-ico130 {
  &:before {
    content: $mam-ico130;
  }
}

.mam-ico131 {
  &:before {
    content: $mam-ico131;
  }
}

.mam-ico132 {
  &:before {
    content: $mam-ico132;
  }
}

.mam-ico133 {
  &:before {
    content: $mam-ico133;
  }
}

.mam-ico134 {
  &:before {
    content: $mam-ico134;
  }
}

.mam-ico135 {
  &:before {
    content: $mam-ico135;
  }
}

.mam-ico136 {
  &:before {
    content: $mam-ico136;
  }
}

.mam-ico137 {
  &:before {
    content: $mam-ico137;
  }
}

.mam-ico138 {
  &:before {
    content: $mam-ico138;
  }
}

.mam-ico139 {
  &:before {
    content: $mam-ico139;
  }
}

.mam-ico140 {
  &:before {
    content: $mam-ico140;
  }
}

.mam-ico141 {
  &:before {
    content: $mam-ico141;
  }
}

.mam-ico142 {
  &:before {
    content: $mam-ico142;
  }
}

.mam-ico143 {
  &:before {
    content: $mam-ico143;
  }
}

.mam-ico144 {
  &:before {
    content: $mam-ico144;
  }
}

.mam-ico145 {
  &:before {
    content: $mam-ico145;
  }
}

.mam-ico146 {
  &:before {
    content: $mam-ico146;
  }
}

.mam-ico147 {
  &:before {
    content: $mam-ico147;
  }
}

.mam-ico148 {
  &:before {
    content: $mam-ico148;
  }
}

.mam-ico149 {
  &:before {
    content: $mam-ico149;
  }
}

.mam-ico150 {
  &:before {
    content: $mam-ico150;
  }
}

.mam-ico151 {
  &:before {
    content: $mam-ico151;
  }
}

.mam-ico152 {
  &:before {
    content: $mam-ico152;
  }
}

.mam-ico153 {
  &:before {
    content: $mam-ico153;
  }
}

.mam-ico154 {
  &:before {
    content: $mam-ico154;
  }
}

.mam-ico155 {
  &:before {
    content: $mam-ico155;
  }
}

.mam-ico156 {
  &:before {
    content: $mam-ico156;
  }
}

.mam-ico157 {
  &:before {
    content: $mam-ico157;
  }
}

.mam-ico158 {
  &:before {
    content: $mam-ico158;
  }
}