.mam_backlink_container {
    margin-bottom: 0;
    .backlink_anchor {
        line-height: 1;
        align-items: center;
        color: #05164d;
        display: inline-flex;
        font-weight: 700;
        cursor: pointer;
        .backlink_iconWrapper {
            align-items: center;
            display: inline-flex;
            height: 48px;
            justify-content: flex-start;
            width: 48px;
            text-align: center;
            span {
                width: 100%;
                padding-bottom: 6px;
                transform: rotate(180deg);
            }
        }
        span.mam-ico26:before {
            font-weight: 700;
        }
        @media only screen and (min-width:$screen-md) {
            &:hover {
                span.mam-ico26:before {
                    color: $mam-highlight-blue;
                }
            }
        }
        .backlink_text {
            display: inline-block;
            margin-top: 4px;
        }
    }
}